.psectionscontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
}

.psectioncontainer {
    width: 100%;
    border-radius: 10px;
    display: flex;
    padding-bottom: 40px;
}

.psectioncontainerreverse {
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 40px;
}

.pimagecontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-left: 10%;
    overflow: hidden;
    flex-direction: column;
}

.caption {
    align-self: center;
    text-align: center;
    margin-right: 20%;

}

.pimage {
    max-width: 80%;
    align-self: center;
}

.ptextcontainer {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.ptitle {
    text-align: center;
    font-size: 2.25vw;
}

.pdescription {
    padding-right: 10%;
    padding-left: 10%;
    font-size: 1.35vw;
}

.ptitlereverse {
    text-align: center;
    font-size: 2.25vw;
}

.pdescriptionreverse {
    padding-left: 10%;
    padding-right: 10%;
    font-size: 1.35vw;

}

.embla {  
    overflow: hidden;
}

.embla__container {
      display: flex;
}

.embla__slide { 
    flex: 0 0 100%;  
    min-width: 0;   
}

@media (max-width:920px)  {

.psectioncontainer {
    flex-direction: column-reverse;
}

.psectioncontainerreverse {
    flex-direction: column-reverse;
}

.pimagecontainer {
    width: 100%;
}

.ptextcontainer {
    width: 100%;
    padding-bottom: 40px;
}

.ptitle {
    text-align: center;
    font-size: 3.5vw;
}

.pdescription {
    padding-right: 10%;
    padding-left: 10%;
    font-size: 2.25vw;
}

.ptitlereverse {
    text-align: center;
    font-size: 3.5vw;
}

.pdescriptionreverse {
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2.25vw;
}

.caption {
    font-size: 2vw;
    
}
}