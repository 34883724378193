.samplecontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2vw;
}

.sampletext {
    margin-top: 12px;
    width: 80%;
    margin-bottom: 12px;
}

.ssectioncontainer {
    width: 100%;
    border-radius: 10px;
    display: flex;
    padding-bottom: 40px;
}



@media (max-width:920px)  {


    .ssectioncontainer {
        flex-direction: column;
    }
    
}