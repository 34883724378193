.homecontainer {
    width:100%;
    height: 100%;
    background:linear-gradient(to bottom right, rgba(112, 28, 133, 0.929),transparent);
    background-color:rgb(50, 172, 212);
    display: flex;
    align-items: center;
}

.aboutcontainer {
    width: 50vw;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.abouttext {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.introduction {
    font-size: 4vw;
    padding: 20px;
    color: rgb(212, 212, 212);
}

.description {
    font-size: 2vw;
    color: rgb(212, 212, 212);
}

.imagecontainer {
    width: 50vw;
    height: 50%;
    display: flex;
    justify-content: center;
}

.portrait {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}

@media (max-width:920px)  {
    .aboutcontainer {
        width: 80vw;
        height: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .abouttext {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: flex-start;
    }
    
    .introduction {
        font-size: 4vw;
        padding: 20px;
        color: rgb(212, 212, 212);
    }

    .homecontainer{
        flex-direction: column-reverse;
    }

    .imagecontainer{
        width: 100%;
        align-items: center;
    }

    .portrait {
        max-width: 100%;
        width: 40%;
        margin-top: 80px;
        height: auto;
        border-radius: 50%;
    }

    .introduction {
        font-size: 6.5vw;
    }
    
    .description {
        font-size: 4.5vw;
    }
}